<template>
  <div>
    <v-btn
      v-if="permission && !show"
      small
      title="Fichiers jointes"
      class="block-icon-reglement-fac"
      @click.prevent.stop="handleUploadFiles()"
    >
      <font-awesome-icon icon="file" />
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-show"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Pièces jointes </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add mt-2" ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="files"
                  counter
                  label="Fichiers"
                  multiple
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <div v-if="files.length > 0">
              <v-row>
                <p class="file_name ml-4">Nouveau(x) Fichier(s)</p>
              </v-row>
              <v-row v-for="(file, index) in files" :key="'file' + index">
                <v-col cols="4">
                  <p class="ml-4">
                    {{ file.name }}
                  </p>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="file.description"
                    :persistent-placeholder="true"
                    rows="3"
                    required
                    :rules="[v => !!v || 'Description obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  ></v-textarea
                ></v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-2 button-add"
                    small
                    color="red"
                    @click.prevent.stop="deleteFileUpload(index)"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </div>
            <div v-if="pieceJointe.length > 0">
              <v-row>
                <p class="file_name ml-4">Fichier(s) Récent(s)</p>
              </v-row>
              <v-row
                v-for="(file, index) in pieceJointe"
                :key="'file-recent' + index"
              >
                <v-col cols="4">
                  <p class="ml-4">
                    <a :href="file.link" target="_blank">{{ file.name }}</a>
                  </p>
                </v-col>
                <v-col cols="7">
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="file.description"
                    :persistent-placeholder="true"
                    rows="3"
                    required
                    :rules="[v => !!v || 'Description obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  ></v-textarea
                ></v-col>
                <v-col cols="1">
                  <v-btn
                    class="mx-2 button-add"
                    small
                    color="red"
                    @click.prevent.stop="deleteFile(file, index)"
                  >
                    <v-icon dark>
                      mdi-close
                    </v-icon>
                  </v-btn></v-col
                >
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleSubmitFiles"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="hideModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card v-if="show">
      <v-card-title class="text-h6 title-modal">
        <v-label class="label-header"> Pièces jointes </v-label>
      </v-card-title>
      <v-divider class="line-hr"></v-divider>
      <v-card-text class="body-card">
        <v-form class="form-add mt-2" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="files"
                counter
                label="Fichiers"
                multiple
                placeholder="Aucun fichier selectionné"
                :persistent-placeholder="true"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
                color="#704ad1"
                item-color="#704ad1"
                @change="handelUpdateFilesList"
              >
                <template v-slot:selection="{ text }">
                  <v-chip label small>
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <div v-if="files.length > 0">
            <v-row>
              <p class="file_name ml-4">Nouveau(x) Fichier(s)</p>
            </v-row>
            <v-row v-for="(file, index) in files" :key="'file' + index">
              <v-col cols="4">
                <p class="ml-4">
                  {{ file.name }}
                </p>
              </v-col>
              <v-col cols="7">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="file.description"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  :rules="[v => !!v || 'Description obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                  @input="handelUpdateFilesList"
                ></v-textarea
              ></v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-2 button-add"
                  small
                  color="red"
                  @click.prevent.stop="deleteFileUpload(index, true)"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn></v-col
              >
            </v-row>
          </div>
          <div v-if="pieceJointe.length > 0">
            <v-row>
              <p class="file_name ml-4">Fichier(s) Récent(s)</p>
            </v-row>
            <v-row
              v-for="(file, index) in pieceJointe"
              :key="'file-recent' + index"
            >
              <v-col cols="4">
                <p class="ml-4">
                  <a :href="file.link" target="_blank">{{ file.name }}</a>
                </p>
              </v-col>
              <v-col cols="7">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="file.description"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  :rules="[v => !!v || 'Description obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                  @input="handelUpdateFilesListRecente(file)"
                ></v-textarea
              ></v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-2 button-add"
                  small
                  color="red"
                  @click.prevent.stop="deleteFile(file, index)"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn></v-col
              >
            </v-row>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    permission: { required: true },
    item: { required: true },
    typeVoir: { required: false },
    show: { default: false },
    reglement: { default: null },
    noItems: { default: false },
    resetModalData: { default: false }
  },
  data() {
    return {
      dialog: false,
      pieceJointe: this.noItems ? [] : this.item,
      files: [],
      error: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([
      'updateFileReglement',
      'AttachFiles',
      'deleteAttachementReglement'
    ]),
    handelUpdateFilesList() {
      this.$emit('showFiles', this.files);
    },
    handleUploadFiles() {
      this.pieceJointe = JSON.parse(JSON.stringify(this.item));
      this.dialog = true;
    },
    handelUpdateFilesListRecente(file) {
      this.$emit('showFilesRecent', this.pieceJointe);
    },
    async handleSubmitFiles() {
      this.error = [];
      if (this.$refs.form.validate()) {
        this.error = [];
        if (this.files.length != 0 || this.pieceJointe.length != 0) {
          this.loading = true;
          if (this.pieceJointe.length != 0) {
            let bodyFormData = new FormData();
            for (let i = 0; i < this.pieceJointe?.length; i++) {
              bodyFormData.append(
                'files[' + i + '][file_id]',
                this.pieceJointe[i].id
              );
              bodyFormData.append(
                'files[' + i + '][description]',
                this.pieceJointe[i].description
              );
            }
            const response = await this.updateFileReglement({
              bodyFormData: bodyFormData,
              reglement: this.reglement
            });
            if (response.success) {
              if (this.files.length != 0) {
                let bodyFormData = new FormData();
                bodyFormData.append('payment_id', this.reglement.id);
                for (let i = 0; i < this.files?.length; i++) {
                  bodyFormData.append('files[' + i + '][file]', this.files[i]);
                  if (this.files[i]['description'] != undefined) {
                    bodyFormData.append(
                      'files_descriptions[' + i + ']',
                      this.files[i]['description']
                    );
                  }
                }
                const responseAttach = await this.AttachFiles({
                  bodyFormData: bodyFormData,
                  payment_id: this.reglement.id
                });
                if (responseAttach.success) {
                  this.loading = false;
                  this.files = [];
                  return true;
                }
              } else {
                this.files = [];
                this.loading = false;
                return true;
              }
            }
          } else {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('payment_id', this.reglement.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const responseAttachFiles = await this.AttachFiles({
                bodyFormData: bodyFormData,
                payment_id: this.reglement.id
              });

              if (responseAttachFiles.success) {
                this.loading = false;
                return true;
              }
            }
          }
        }
      }
    },
    hideModal() {
      this.$refs.form.resetValidation();
      this.error = [];
      this.loading = false;
      this.dialog = false;
      this.files = [];
      this.pieceJointe = [];
    },
    deleteFileUpload(index, check) {
      this.files.splice(index, 1);
      if (check) {
        this.$emit('showFiles', this.files);
      }
    },
    async deleteFile(item, index) {
      const response = await this.deleteAttachementReglement({
        id: item.id,
        reglement: this.reglement
      });
      if (response) {
        if (this.show == true) {
          this.pieceJointe.splice(index, 1);
          this.$emit('showFilesRecent', this.pieceJointe);
        }
      }
    }
  },
  watch: {
    item() {
      if (!this.noItems) {
        this.pieceJointe = JSON.parse(JSON.stringify(this.item));
        if (this.show == true) {
          this.files = [];
        }
      }
    },
    resetModalData() {
      this.files = [];
    }
  },
  components: {},
  mounted() {}
};
</script>
<style scoped>
.file_name {
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}
</style>
